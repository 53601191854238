import styled from "styled-components"

export const ContentWrapper = styled.div`
  text-align: justify;

  .mobile-stack {
    @media only screen and (max-width: 480px) {
      flex-direction: column;
      align-items: center;
    }
  }
  
  .with-gap-2 {
  	margin: 2rem 0;
  }
  
  .center {
  	display: flex;
  	flex-direction: row;
  	justify-content: center;
  }
  
  .screenshots-container {
  	display: flex;
  	justify-content: space-between;
  }
  
  .screenshot-container {
    max-width: 20%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    
    h3 {
    	font-size: calc(10px + 2vmin);
    	text-align: center;
    }
    
    @media only screen and (max-width: 480px) {
      max-width: 50%;
      width: 50%;
    }
  }
  
  .image {
  	display: flex; 
  	justify-content: center;
  	width: 60%;
  	margin: auto;
  }

  .image-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
	width: 100%;
    > div > img {
	  max-width: 45%;
	  max-height: 20rem;
      padding: 0.5rem;
    }
    > div {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      width: 100%;
    }
  }
`
